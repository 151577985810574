/*
  
  SASS Imports

*/

@import './sass/_mixins.scss';
@import './sass/_animations.scss';

@font-face {
    font-family: 'Telegraf';
    src: url('font/Telegraf/Telegraf-UltraBold.woff2') format('woff2'),
        url('font/Telegraf/Telegraf-UltraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Telegraf';
    src: url('font/Telegraf/Telegraf-Regular.woff2') format('woff2'),
        url('font/Telegraf/Telegraf-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Telegraf';
    src: url('font/Telegraf/Telegraf-Bold.woff2') format('woff2'),
        url('font/Telegraf/Telegraf-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Telegraf';
    src: url('font/Telegraf/Telegraf-UltraLight.woff2') format('woff2'),
        url('font/Telegraf/Telegraf-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}


*{
  box-sizing: border-box;
}

html,body{
	font-family:'Telegraf',sans-serif;
	color:black;
	overflow:hidden;
}

/*

Splash

*/

.Splash-title{
	position:fixed;
	text-align:center;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	text-transform: lowercase;
	font-size:20vw;
	letter-spacing:-0.03em;
	padding:0;
	margin:0;
	font-weight:800;
	color:#EEE;
	opacity:0.5;

  color:black;
  top:40px;
  left:40px;
  transform:none;
  opacity:1;
  font-size:20px;
  z-index:10;
  padding:5px 20px;
  border-radius:20px;
  background:rgba(220,220,220,0.5);
  backdrop-filter:blur(20px);
}

/*

DragWall

*/


[data-draggable]{
  
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;

}

.DragWall{
  top:0;
  left:0;
  bottom:0;
  right:0;
  background:transparent;
  position:fixed;
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  //cursor: url("https://github.com/chenglou/react-motion/raw/master/demos/demo8-draggable-list/cursor.png") 39 39, auto;
  cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    transform:scale3d(0.99,0.99,1);
    opacity:0;
  animation:scaleIn 0.8s 0.2s ease forwards;
}

/*

Tile

*/

.Tile{
	
	height:100%;
	width:100%;
	position:relative;
	padding:0 10%;
	opacity:0;
	animation:scaleIn 0.8s 0.2s ease forwards;

	display:flex;
	align-items: center;
	img{
		width:100%;
    pointer-events: none;
		border-radius:20px;
		display:block;
		margin:20px auto;
		box-shadow:0 0 50px rgba(0,0,0,0.2);
	}

  .Tile-inner{
    margin:0 auto;
  }

  &.Tile--portrait{
    .Tile-inner{
      width:80%;
    }
  }

  &.Tile--dark{
    .Tile-inner{
      color:white;
    }
  }

  .Tile-heading{
    margin:0;
    padding:10px;
    padding-top:0;
    line-height:130%;
    font-weight:500;
    span{
      font-weight:800;
    }
  }

  &.Tile--noImage{
    .Tile-inner{
      height:70%;
      width:100%;
      padding:15px;
      padding-right:14%;
      border-radius:20px;
      background:#DDD;
        box-shadow:0 0 50px rgba(0,0,0,0.2);
    }

    .Tile-heading{
      padding-top:15px;
      font-size:35px;
      line-height:110%;
      *{
        font-weight:500;
      }
    }
    
  }

  &.Tile--noImage.Tile--long{
    .Tile-inner{
      height:90%;
    }
  }
}